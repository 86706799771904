export default defineAppConfig({
  ui: {
    primary: 'turquoise',
    gray: 'shark',
    strategy: 'merge',
    input: {
      color: {
        white: {
        
        }
      }
    },
    textarea: {
      color: {
        white: {
         
        }
      }
    },
    selectMenu: {



    },
    button: {
      font: 'font-semibold',

      color: {
        primary: {
          solid: 'text-primary-950 bg-primary-400 hover:bg-primary-500'
        }
      }
    }
  }
})