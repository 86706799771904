<script setup lang="ts">
const {t} = useI18n();

useHead({
  script: [
    {
      type: "text/javascript",
      innerHTML: `
     window.CSS.registerProperty({
    name: "--percentage",
    syntax: "<percentage>",
  inherits: false,
  initialValue: "0%",
});`,
      tagPosition: "head",
    },
  ],
  noscript: [
    {
      innerHTML: `  <div class="bg-primary-400 py-1 ">
        <div class="flex items-center gap-1 max-w-7xl justify-center mx-auto">
          <UIcon name="i-material-symbols-warning-outline-rounded"></UIcon>
          <p class="text-sm text-center font-medium text-primary-950">
            ${t("warning.noScript")}
          </p>
        </div>
      </div>`,
    },
  ],
});
</script>
<template>
  
  <NuxtRouteAnnouncer />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
