import { default as companiesF7ihpxG8btMeta } from "/home/jedrz/git_stuff/team-ui/pages/companies.vue?macro=true";
import { default as contact1YoJLO5KJDMeta } from "/home/jedrz/git_stuff/team-ui/pages/contact.vue?macro=true";
import { default as indexejuiF9Njt5Meta } from "/home/jedrz/git_stuff/team-ui/pages/index.vue?macro=true";
import { default as internetGTWuOGHcWLMeta } from "/home/jedrz/git_stuff/team-ui/pages/internet.vue?macro=true";
import { default as login8wHYUdq5auMeta } from "/home/jedrz/git_stuff/team-ui/pages/login.vue?macro=true";
import { default as searchX0j95s9bxjMeta } from "/home/jedrz/git_stuff/team-ui/pages/search.vue?macro=true";
import { default as sentzOhUc8S89wMeta } from "/home/jedrz/git_stuff/team-ui/pages/sent.vue?macro=true";
import { default as speedtestjTrMDzQbebMeta } from "/home/jedrz/git_stuff/team-ui/pages/speedtest.vue?macro=true";
import { default as successUuJJINwL75Meta } from "/home/jedrz/git_stuff/team-ui/pages/success.vue?macro=true";
import { default as telewizjaMUqgnht1RGMeta } from "/home/jedrz/git_stuff/team-ui/pages/telewizja.vue?macro=true";
import { default as component_45stubiFP58uwPlwMeta } from "/home/jedrz/git_stuff/team-ui/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubiFP58uwPlw } from "/home/jedrz/git_stuff/team-ui/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "companies___pl",
    path: "/companies",
    meta: companiesF7ihpxG8btMeta || {},
    alias: ["/internet-dla-firm"],
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: "contact___pl",
    path: "/contact",
    meta: contact1YoJLO5KJDMeta || {},
    alias: ["/kontakt"],
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/",
    meta: indexejuiF9Njt5Meta || {},
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "internet___pl",
    path: "/internet",
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/internet.vue").then(m => m.default || m)
  },
  {
    name: "login___pl",
    path: "/login",
    meta: login8wHYUdq5auMeta || {},
    alias: ["/ibok"],
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "search___pl",
    path: "/search",
    meta: searchX0j95s9bxjMeta || {},
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "sent___pl",
    path: "/sent",
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/sent.vue").then(m => m.default || m)
  },
  {
    name: "speedtest___pl",
    path: "/speedtest",
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/speedtest.vue").then(m => m.default || m)
  },
  {
    name: "success___pl",
    path: "/success",
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "telewizja___pl",
    path: "/telewizja",
    meta: telewizjaMUqgnht1RGMeta || {},
    component: () => import("/home/jedrz/git_stuff/team-ui/pages/telewizja.vue").then(m => m.default || m)
  },
  {
    name: component_45stubiFP58uwPlwMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubiFP58uwPlw
  },
  {
    name: component_45stubiFP58uwPlwMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubiFP58uwPlw
  },
  {
    name: component_45stubiFP58uwPlwMeta?.name,
    path: "/pl-sitemap.xml",
    component: component_45stubiFP58uwPlw
  }
]